import React from 'react';

export const AngleDown = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100"
      height="100"
      viewBox="0 0 24 24"
      stroke-width="500"

    >
      <path d="M1.51,6.079a1.492,1.492,0,0,1,1.06.44l7.673,7.672a2.5,2.5,0,0,0,3.536,0L21.44,6.529A1.5,1.5,0,1,1,23.561,8.65L15.9,16.312a5.505,5.505,0,0,1-7.778,0L.449,8.64A1.5,1.5,0,0,1,1.51,6.079Z"/>
    </svg>
  );

};

export const AngleUp = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100"
      height="100"
      viewBox="0 0 24 24"
    >
      <path d="M22.5,18a1.5,1.5,0,0,1-1.061-.44L13.768,9.889a2.5,2.5,0,0,0-3.536,0L2.57,17.551A1.5,1.5,0,0,1,.449,15.43L8.111,7.768a5.505,5.505,0,0,1,7.778,0l7.672,7.672A1.5,1.5,0,0,1,22.5,18Z"/>
    </svg>
  );

};

export const AngleDoubleUp = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100"
      height="100"
      viewBox="0 0 24 24"
    >
      <path d="M6,18c-.257,0-.514-.099-.709-.295-.39-.392-.388-1.025,.004-1.415l6.001-5.964c.391-.388,1.02-.387,1.41,0l5.999,5.964c.392,.389,.394,1.022,.004,1.414-.389,.393-1.021,.395-1.414,.004l-5.294-5.263-5.296,5.264c-.195,.194-.45,.291-.705,.291Zm12.712-6.56c.388-.393,.384-1.026-.01-1.414l-3.925-3.875c-.741-.742-1.728-1.151-2.777-1.151s-2.036,.409-2.772,1.146l-3.93,3.88c-.394,.388-.397,1.021-.01,1.414,.39,.394,1.021,.396,1.414,.009l3.935-3.884c.729-.729,1.993-.733,2.731,.004l3.93,3.88c.194,.192,.448,.289,.702,.289,.258,0,.516-.099,.712-.297Z"/>
    </svg>
  );

};

export const AngleDoubleDown = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100"
      height="100"
      viewBox="0 0 24 24"
    >
      <path stroke-width="0" d="M12,19c-1.049,0-2.036-.409-2.778-1.151l-3.925-3.875c-.393-.388-.397-1.021-.009-1.414,.387-.394,1.021-.396,1.414-.009l3.929,3.88c.737,.737,2.003,.733,2.731,.004l3.935-3.884c.392-.388,1.023-.385,1.414,.009,.388,.393,.384,1.026-.01,1.414l-3.93,3.88c-.736,.738-1.723,1.146-2.772,1.146Zm.706-5.327l5.999-5.964c.392-.389,.394-1.022,.004-1.414-.39-.393-1.022-.395-1.414-.004l-5.294,5.263L6.705,6.291c-.391-.389-1.024-.386-1.415,.004-.389,.392-.387,1.025,.004,1.415l6.001,5.964c.195,.194,.45,.291,.705,.291s.51-.097,.705-.291Z"/>
    </svg>
  );

};

export const Broom = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100"
      height="100"
      viewBox="0 0 24 24"
      strokeWidth="1"
    >
      <path d="M23.715,2.285a1.013,1.013,0,0,0-1.43,0L17.521,7.049l-.32-.313a5.008,5.008,0,0,0-6.429-.479A16.618,16.618,0,0,1,6.224,8.685L4.15,9.293a5.036,5.036,0,0,0-3.113,2.635A4.973,4.973,0,0,0,.9,15.947a12.95,12.95,0,0,0,12.112,8.064h.924a1.011,1.011,0,0,0,.578-.182A15.288,15.288,0,0,0,21.224,13.62a5.029,5.029,0,0,0-1.453-4.374l-.8-.784,4.747-4.747A1.013,1.013,0,0,0,23.715,2.285Zm-10.107,19.7h-.6A11.3,11.3,0,0,1,8.7,21.138l.011-.006a11.546,11.546,0,0,0,4.351-3.8l.518-.761a1.01,1.01,0,0,0-1.67-1.138l-.518.761A9.535,9.535,0,0,1,7.8,19.327l-1.251.63a10.757,10.757,0,0,1-2.583-2.57,11.625,11.625,0,0,0,4.377-2.664,1.011,1.011,0,0,0-1.414-1.446,9.617,9.617,0,0,1-3.98,2.32c-.061-.135-.127-.267-.182-.406a2.906,2.906,0,0,1,.085-2.381,3.023,3.023,0,0,1,1.864-1.578l2.073-.608a15.364,15.364,0,0,0,3.426-1.588l7.915,7.712A14.192,14.192,0,0,1,13.608,21.989Zm5.62-8.683a12.421,12.421,0,0,1-.309,1.387L11.948,7.9l0,0a3.011,3.011,0,0,1,1.755-.566,2.973,2.973,0,0,1,2.084.849l2.569,2.509A3.01,3.01,0,0,1,19.228,13.306Z"/>
    </svg>
  );

};

